.field {
  display: flex;
  flex-direction: column;

}
.label {
  color: #2e64ae;
  font-size: 15px;
  font-weight: bold;
  text-align: start;
}
