.submitBtn {
  height: 35px;
  width: 150px;
  font-size: 15px;
  background-color: #638a2f;
  color: white;
  border-width: 0px;
  border-radius: 8px;
  display: block;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.submitBtn:hover {
  background-color: #90b85d;
}

.btnWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formContainer {
  background-color: white;
  width: 350px;
  border-radius: 20px;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.main {
  width: 100vw;
  height: 100vh;
  /* background-image: "medical_icon_image.jpeg"; */
  background-color: skyblue;
  display: flex;
  align-items: center;
  justify-content: center;
}
